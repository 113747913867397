import React from 'react';
import SEO from '../components/seo';
import HomePage from '../pagesComponents/HomePage';
import { redirectIfSpanish } from '../utils';

export default function Dg24Page({path, location = {}}) {
  if (redirectIfSpanish(path, location.search))
    return <SEO title="Writelytic" lang="en"/>
  
  return (
    <HomePage appContextValue={{language: "en", digistore24: true, path}} />
  );
}
